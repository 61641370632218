import { ApiMethod, AlegroApi, IAlegroApi } from './api'
import * as superagent from 'superagent'
import { Request, Response } from 'superagent'
import { navigate } from 'gatsby'

const isBrowser = typeof window !== 'undefined' && window
const getResponse = (res: Response) => res.body

export const getToken = () => isBrowser ? localStorage.getItem('token') : ''
export const setToken = (token: string) => isBrowser ? localStorage.setItem('token', token) : ''
export const setUser = (user: string) => isBrowser ? localStorage.setItem('user', user) : ''
export const getUser = () => isBrowser ? localStorage.getItem('user') : ''
export const logout = () => {
  if (isBrowser) {
    localStorage.clear()
    navigate('/')
  }
}

const interceptor = (req: superagent.SuperAgentRequest) => {
  req.on('response', (res: Response) => {
    if (res.status === 401 && (req.url.indexOf('/auth') < 0)) {

    }
  })
}

const getObservable = (config: AlegroApi): Promise<any> => {
  const url = config.getUrl()
  const data = config.getData()
  const method = config.getMethod()
  const headers = config.getHeaders()
  const agent = (superagent.agent() as any).use((req: superagent.SuperAgentRequest) => interceptor(req))

  const addHeaders = async (req: Request) => {
    const token = localStorage.getItem('token')

    if (headers) {
      req.set(headers)
    }

    if (config.isProtected()) {
      req.set('authorization', `Bearer ${token}`)
    }

    return req
  }

  switch (method) {
    case ApiMethod.GET:
      return addHeaders(agent.get(url)).then(getResponse)
    case ApiMethod.POST:
      return addHeaders(agent.post(url).send(data)).then(getResponse)
    case ApiMethod.PUT:
      return addHeaders(agent.put(url).send(data)).then(getResponse)
    case ApiMethod.PATCH:
      return addHeaders(agent.patch(url).send(data)).then(getResponse)
    case ApiMethod.DELETE:
      return addHeaders(agent.delete(url)).then(getResponse)
    default:
      return new Promise<Error>(() => new Error('Api method not recognised'))
  }
}

export const request = (options: IAlegroApi) => {
  const ApiConfig = new AlegroApi(options)

  return getObservable(ApiConfig)
}
