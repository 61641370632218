import { FormFieldProps, FormFieldType } from '../../FormField'

export const topFields: FormFieldProps[] = [
  {
    type: FormFieldType.text,
    placeholder: 'First name*',
    name: 'firstName',
    required: true,
    validator: new RegExp('^[\u0400-\u04FFa-z\\s]+$', 'i'),
    errorText: 'Invalid first name',
  },
  {
    type: FormFieldType.text,
    placeholder: 'Last name*',
    name: 'lastName',
    required: true,
    validator: new RegExp('^[\u0400-\u04FFa-z\\s]+$', 'i'),
    errorText: 'Invalid last name',
  },
]

export const middleFields: FormFieldProps[] = [
  {
    type: FormFieldType.text,
    placeholder: 'Company name*',
    name: 'companyName',
    required: true,
    validator: new RegExp('^.+$', 'i'),
    errorText: 'Invalid company name',
  },
  {
    type: FormFieldType.email,
    placeholder: 'Email*',
    name: 'email',
    required: true,
    validator: new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$', 'i'),
    errorText: 'Invalid email',
  },
]

export const bottomFields: FormFieldProps[] = [
  { type: FormFieldType.textarea, placeholder: 'Message', name: 'message' },
]

export const fields = [...topFields, ...middleFields, ...bottomFields]
