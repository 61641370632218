import * as React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import preview from '../../static/meta-preview.png'

import { SeoProps } from './index'

const SEO: React.FunctionComponent<SeoProps> = ({
  description,
  lang,
  meta = [],
  keywords = [],
  title,
}): JSX.Element => {
  const { site } = useStaticQuery(seoQuery)
  const image: string = `${site.siteMetadata.domain}${preview}`
  const metaDescription: string = description || site.siteMetadata.description
  const metaKeywords: string[] = [
    ...keywords,
    ...(SEO!.defaultProps!.keywords || []),
  ]
  const loadFonts: boolean = title.toLowerCase() === 'home'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'viewport',
          content: '',
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'name',
          content: site.siteMetadata.title,
        },
        {
          name: 'image',
          content: image,
        },
        {
          property: 'og:title',
          content: site.siteMetadata.title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          name: 'twitter:card',
          content: 'Teonica web agency',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: site.siteMetadata.title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: image,
        },
      ]
        .concat(
          metaKeywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:400,500,700"
        rel="stylesheet"
      />
      {loadFonts && (
        <link
          href="https://fonts.googleapis.com/css?family=Great+Vibes|Playfair+Display:900"
          rel="stylesheet"
        />
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: ['allergro', 'meat'],
  description: ``,
  title: '',
}

const seoQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        domain
      }
    }
  }
`

export default SEO
