import * as React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { getUser, logout } from '../../utils/network'

import { HeaderProps, HeaderLink, HeaderStyle, HeaderPosition } from '.'

import LogoLight from '../../images/header-logo--light.svg'
import LogoDark from '../../images/header-logo--dark.svg'
import { ReactComponent as UserIcon } from '../../images/user-icon.svg'
import { ReactComponent as DownIcon } from '../../images/arrow-down-icon.svg'

import './Header.scss'

const Header: React.FunctionComponent<HeaderProps> = ({
  links = [],
  position,
  style,
  border,
  hide,
}): JSX.Element => {
  const userName = getUser()

  if (userName) {
    links = links.filter(item => item.value !== '/app/auth/login')
  }

  return (
    <Container>
      <Row>
        <Col>
          <header
            className={classNames(
              ['header', 'd-flex', `is-${style}`, `is-${position}`],
              {
                'has-border': border,
                'is-hidden': hide,
              }
            )}
          >
            <div className="header__logo">
              <Link to="/" className="d-flex">
                <img
                  src={style === 'light' ? LogoLight : LogoDark}
                  alt="Allegro"
                />
              </Link>
            </div>

            <nav className="header__nav">
              <ul className="nav d-flex">
                {links.map((link: HeaderLink, i: number) => (
                  <li
                    className={classNames(
                      link.classNames || 'nav__item',
                      'd-flex'
                    )}
                    key={i}
                  >
                    <Link
                      className={classNames('item__link', `is-${style}`)}
                      to={link.value}
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
                {!!userName && (
                  <React.Fragment>
                    <li className={classNames('nav__item', 'd-flex')}>
                      <Link
                        className={classNames('item__link', `is-${style}`)}
                        to='/app/offers'
                      >
                        Offers
                      </Link>
                    </li>
                    <li
                      className="nav__item d-flex profile-dropdawn-container"
                      key={links.length + 1}
                    >
                      <Dropdown alignRight>
                        <Dropdown.Toggle variant="link" id="user-dropdown">
                          <UserIcon className="icon-user"/>
                          <span className={`item__link is-${style} user-name`}>{userName}</span>
                          <DownIcon className="icon-arrow" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#" onClick={logout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                    </li>
                  </React.Fragment>
                )}
              </ul>
            </nav>
          </header>
        </Col>
      </Row>
    </Container>
  )
}

Header.defaultProps = {
  links: [
    { value: '#services', label: 'Services' },
    { value: '#products', label: 'Products' },
    { value: '#clients', label: 'Our clients' },
    { value: '#contacts', label: 'Contact us' },
    { value: '/app/auth/login', label: 'Log in', classNames: 'nav__login' },
  ],
  style: HeaderStyle.dark,
  position: HeaderPosition.relative,
  border: true,
  hide: false,
}

export default Header
