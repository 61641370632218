import * as React from 'react'
import * as classNames from 'classnames'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { TooltipProps } from '.'

import './Tooltip.scss'
import { Placement } from 'react-bootstrap/Overlay'

const getTooltipClass = (type: Placement | string = ''): string => {
  let baseClass: string = 'bs-popover-'
  if (type.match('bottom')) {
    return `${baseClass}bottom`
  } else {
    return ''
  }
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  title,
  id,
  children,
  placement,
  trigger,
  onEntered,
  onExited,
}): JSX.Element => {
  if (!title) return <React.Fragment>{children}</React.Fragment>

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      onEntered={onEntered}
      onExited={onExited}
      overlay={
        <Popover
          id={id}
          className={classNames(['app__tooltip', getTooltipClass(placement)])}
        >
          {title}
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

Tooltip.defaultProps = {
  placement: 'left',
  trigger: 'hover',
  onEntered: () => {},
  onExited: () => {},
}

export default Tooltip
