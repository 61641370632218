import * as React from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import HomeHeading from '../HomeHeading/HomeHeading'
import ClientsCard from './ClientsCard/ClientsCard'
import ModalHeader from '../ModalWindow/ModalHeader/ModalHeader'

import { HomeHeadingStyle } from '../HomeHeading'
import { ClientsCardProps } from './ClientsCard'

import './Clients.scss'

const Clients: React.FunctionComponent = (): JSX.Element => {
  const {
    bgImage,
    allStrapiSectionourclients: {
      nodes: [data],
    },
  } = useStaticQuery(pageQuery)

  const { title, description, clients } = data

  const formattedData: ClientsCardProps[] = clients.map((client: any) => ({
    id: client.id,
    name: client.name,
    company: client.company,
    info: client.about,
  }))

  const [showModal, toggleModal] = React.useState<boolean>(false)
  const [cardId, selectCard] = React.useState<string | null>(null)
  const toggleModalHandler = (id: string | null = null): void => {
    toggleModal(!showModal)
    selectCard(id)
  }

  const selectedCard: ClientsCardProps | null =
    formattedData.find(client => !!cardId && client.id === cardId) || null

  return (
    <section id="clients" className="clients">
      <Img fluid={bgImage.childImageSharp.fluid} />
      <Container>
        <HomeHeading
          title={title}
          subtitle={description}
          style={HomeHeadingStyle.light}
        />
        <Row>
          {formattedData.map((client, i: number) => (
            <Col className="col-client" key={i} xs={6}>
              <ClientsCard {...client} handler={toggleModalHandler} />
            </Col>
          ))}
        </Row>
      </Container>

      <Modal
        centered
        show={showModal}
        className="app-modal  app-modal--large"
        onHide={() => toggleModalHandler()}
      >
        {cardId && (
          <React.Fragment>
            <ModalHeader
              title={selectedCard!.name}
              subtitle={selectedCard!.company}
              onClose={toggleModalHandler}
            />

            <Modal.Body>
              <p className="pre-line">{selectedCard!.info}</p>
            </Modal.Body>
          </React.Fragment>
        )}
      </Modal>
    </section>
  )
}

export default Clients

export const pageQuery = graphql`
  query {
    bgImage: file(relativePath: { eq: "bg-clients@2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allStrapiSectionourclients {
      nodes {
        title
        description
        clients {
          id
          name
          company
          about
        }
      }
    }
  }
`
