import * as React from 'react'
import Img from 'gatsby-image'
import { TeamMemberProps } from '.'
import './TeamMember.scss'

const TeamMember: React.FunctionComponent<TeamMemberProps> = ({
  name,
  position,
  info,
  image,
}): JSX.Element => {
  return (
    <div className="member-wrapper d-flex">
      <div className="team-member d-flex">
        <div className="team-member__content">
          <Img fluid={image} />
          <h4 className="team-member__name">{name}</h4>
          <span className="team-member__position">{position}</span>
          <p className="team-member__info">{info}</p>
        </div>
        <a
          className="team-member__link"
          href={`mailto:${process.env.GATSBY_EMAIL_TARGET}`}
        >
          Contact
        </a>
      </div>
    </div>
  )
}

export default TeamMember
