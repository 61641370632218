export enum HomeHeadingStyle {
  light = 'light',
  dark = 'dark',
}

export interface HomeHeadingProps {
  title: string
  subtitle?: string
  style?: HomeHeadingStyle
}
