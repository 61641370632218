import * as React from 'react'
import * as classNames from 'classnames'
import { Link } from 'gatsby'

import { Container, Row, Col } from 'react-bootstrap'
import { FooterProps } from '.'

import Logo from '../../images/footer-logo.svg'
import PhoneIcon from '../../images/phone.svg'

import './Footer.scss'

const PhoneLink = () => (
  <span className="phone-container">
    <img src={PhoneIcon} alt="Phone" />
    <span className="phone-number">+61 8 9336 0000</span>
  </span>
)

const Footer: React.FunctionComponent<FooterProps> = ({
  links = [],
  hide,
}): JSX.Element => {
  return (
    <footer className={classNames('footer', { 'is-hidden': hide })}>
      <Container>
        <Row>
          <Col>
            <div className="footer__wrapper">
              <div className="footer__logo">
                <img src={Logo} alt="Allegro" />
              </div>
              <div className="footer__links">
                {links.map((link, i) => (
                  <a className="footer__link" key={i} href={link.value}>
                    {link.label}
                  </a>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="footer__copy">
              &copy; {(new Date).getFullYear()} All rights reserved
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer

Footer.defaultProps = {
  links: [
    { value: 'tel:+61893360000', label: <PhoneLink /> },
    { value: '/termsOfUse', label: 'Terms of use' },
  ],
  hide: false,
}
