import * as React from 'react'
import { Modal } from 'react-bootstrap'

import ModalCloseButton from '../ModalCloseButton/ModalCloseButton'

import { HeaderModalProps } from '.'

const ModalHeader: React.FunctionComponent<HeaderModalProps> = ({
  title,
  subtitle,
  onClose,
  className = ''
}): JSX.Element => (
    <Modal.Header className={className}>
      <Modal.Title>
        <h4 className="app-modal__title">{title}</h4>
        {subtitle && <p className="app-modal__subtitle">{subtitle}</p>}
      </Modal.Title>
      <ModalCloseButton onClose={onClose} />
    </Modal.Header>
  )

ModalHeader.defaultProps = {
  title: '',
  subtitle: '',
}

export default ModalHeader
