import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'

import HomeHeading from '../HomeHeading/HomeHeading'
import TeamMember from './TeamMember/TeamMember'

import { getImage } from '../../utils/image'

import { TeamMemberProps } from './TeamMember'

import './Team.scss'

const Team: React.FunctionComponent = (): JSX.Element => {
  const {
    allStrapiSectionwhoweares: {
      nodes: [data],
    },
  } = useStaticQuery(pageQuery)

  const { title, description, contacts } = data

  const formattedData: TeamMemberProps[] = contacts.map((contact: any) => ({
    name: contact.name,
    position: contact.position,
    info: contact.about,
    image: getImage(contact.photo),
  }))

  return (
    <section className="team">
      <Container>
        <HomeHeading title={title} subtitle={description} />
        <Row>
          {formattedData.map((contact, i: number) => (
            <Col key={i} xs={4}>
              <TeamMember {...contact} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Team

export const pageQuery = graphql`
  query {
    allStrapiSectionwhoweares {
      nodes {
        title
        description
        contacts {
          name
          position
          about
          photo {
            publicURL
            childImageSharp {
              fluid(maxWidth: 335, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
