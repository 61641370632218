export enum GatsbyImageType {
  fluid = 'fluid',
  fixed = 'fixed',
}

export const getImageByPath = <T>(
  data: any = [],
  path: string = '',
  type: GatsbyImageType = GatsbyImageType.fluid
): T | undefined => {
  if (!data.length || !path) return undefined

  const img = data.find((item: any) => item.node.relativePath === path)

  return (img && img.node.childImageSharp[type]) || undefined
}

export const getImage = <T>(img: any, type: GatsbyImageType = GatsbyImageType.fluid): T | undefined => {
  return (img && img.childImageSharp[type]) || undefined
}
