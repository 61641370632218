import * as React from 'react'
import * as classNames from 'classnames'
import './HomeHeading.scss'

import { HomeHeadingProps, HomeHeadingStyle } from '.'
import { Row, Col } from 'react-bootstrap'

const HomeHeading: React.FunctionComponent<HomeHeadingProps> = ({
  title,
  subtitle,
  style,
}): JSX.Element => {
  return (
    <Row>
      <Col xs={{ span: '8', offset: '2' }}>
        <div className={classNames(['home-heading', style])}>
          <h2 className={classNames(['home-heading__title', `is-${style}`])}>
            {title}
          </h2>
          {subtitle && (
            <p
              className={classNames(['home-heading__subtitle', `is-${style}`])}
            >
              {subtitle}
            </p>
          )}
        </div>
      </Col>
    </Row>
  )
}

HomeHeading.defaultProps = {
  title: '',
  subtitle: '',
  style: HomeHeadingStyle.dark,
}

export default HomeHeading
