export interface FormFieldProps {
  type?: string
  placeholder: string
  name: string
  required?: boolean
  value?: string
  error?: string
  touched?: boolean
  validator?: RegExp
  errorText?: string
  onChange?(e: React.ChangeEvent): void
}

export enum FormFieldType {
  textarea = 'textarea',
  text = 'text',
  email = 'email',
  password = 'password',
}

export const getComponentPropsByType = ({
  type,
  placeholder,
  name,
  required,
  value,
  onChange,
}: FormFieldProps): FormFieldProps => {
  const compProps = {
    type,
    placeholder,
    name,
    required,
    value,
    onChange,
  }

  switch (type) {
    case FormFieldType.textarea:
      delete compProps['type']
      return compProps

    default:
      return compProps
  }
}
