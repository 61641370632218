export interface HeaderLink {
  value: string
  label: string
  classNames?: string
}

export enum HeaderStyle {
  light = 'light',
  dark = 'dark',
}

export enum HeaderPosition {
  relative = 'relative',
  fixed = 'fixed',
}

export interface HeaderProps {
  links?: HeaderLink[]
  style?: HeaderStyle
  position?: HeaderPosition
  border?: boolean
  hide?: boolean
}
