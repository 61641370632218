import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { CertificateProps } from '.'

import './Certificate.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { FixedObject } from 'gatsby-image'
import { getImageByPath, GatsbyImageType } from '../../utils/image'

const certificates: CertificateProps[] = [
  {
    title: 'Austmeat Certified Exporter',
    image: undefined,
    path: 'certificates/certificate1@3x.png',
  },
  {
    title: 'Halal Certified products',
    image: undefined,
    path: 'certificates/certificate2@3x.png',
  },
]

const Certificate: React.FunctionComponent = (): JSX.Element => {
  const {
    allFile: { edges: data },
  } = useStaticQuery(query)

  return (
    <section className="certificates">
      <Container>
        <Row className="justify-content-center">
          {certificates.map((certificate, i) => {
            return (
              <Col xs={3} key={i}>
                <div className="certificates__wrapper">
                  <Img
                    fixed={getImageByPath<FixedObject>(
                      data,
                      certificate.path,
                      GatsbyImageType.fixed
                    )}
                  />
                  <p className="certificates__title">{certificate.title}</p>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}

export default Certificate

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { regex: "/certificates/" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fixed(width: 125, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`
