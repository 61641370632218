import * as React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { ProductsCardProps } from '.'

import './ProductsCard.scss'

const ProductsCard: React.FunctionComponent<ProductsCardProps> = ({
  title,
  url,
  image,
}): JSX.Element => (
  <div className="product-card d-flex">
    <Img fluid={image} />
    <div className="product-card__content d-flex">
      <h4 className="product-card__title">{title}</h4>
      <Link className="product-card__link" to={url}>
        Read more
      </Link>
    </div>
  </div>
)

export default ProductsCard
