import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'

import HomeHeading from '../HomeHeading/HomeHeading'

import { getImage, GatsbyImageType } from '../../utils/image'

import './Brands.scss'

const Brands: React.FunctionComponent = (): JSX.Element => {
  const {
    allStrapiSectionbrands: {
      nodes: [data],
    },
  } = useStaticQuery(pageQuery)

  const { title, brandsitems } = data

  const formattedData: FixedObject[] = brandsitems.map((item: any) =>
    getImage(item.image, GatsbyImageType.fixed)
  )

  return (
    <section className="brands">
      <Container>
        <HomeHeading title={title} />
        <Row>
          {formattedData.map((item, i: number) => (
            <Col key={i} xs={3}>
              <div className="brands__image-wrapper d-flex">
                <Img fixed={item} />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Brands

export const pageQuery = graphql`
  query {
    allStrapiSectionbrands {
      nodes {
        title
        brandsitems {
          name
          image {
            publicURL
            childImageSharp {
              fixed(height: 125, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`
