import { FormFieldProps } from '../components/FormField'

function validate(errorText: string = '', regExp: RegExp | null = null) {
  return function(value: string): string | null {
    if (!errorText) return null

    if (!value) return 'This is a required field'

    if (regExp && !regExp.test(value)) {
      return errorText
    }

    return null
  }
}

const validateForm = (fields: FormFieldProps[]) => (values: {
  [key: string]: string
}) => {
  let errors: { [key: string]: string | null } = {}

  errors = fields.reduce((errors, field) => {
    const errorValue = validate(field.errorText, field.validator)(
      values[field.name]
    )

    if (errorValue && field.required) {
      errors[field.name] = errorValue
    }

    return errors
  }, errors)

  return !Object.keys(errors).length ? undefined : errors
}

export default validateForm
