import * as React from 'react'
import ReactMapGl, {
  Marker,
  MapboxProps,
  ViewState,
  MarkerProps,
  PopupProps,
  Popup,
} from 'react-map-gl'

import MarkerIcon from '../../../images/map-marker.svg'

import './Map.scss'

const Map: React.FunctionComponent = (): JSX.Element => {
  const [showPopup, togglePopup] = React.useState<boolean>(true)
  const [viewport, setViewport] = React.useState<ViewState>({
    longitude: 115.7458471,
    latitude: -32.0541031,
    zoom: 15,
  })

  const markerPosition: MarkerProps = {
    longitude: 115.7458471,
    latitude: -32.0541031,
    offsetLeft: -12,
    offsetTop: -12,
  }

  const mapSize: MapboxProps = {
    height: 420,
    width: 640,
  }

  const popupPosition: PopupProps = {
    longitude: 115.7458471,
    latitude: -32.0541031,
    offsetTop: -17,
    closeButton: false,
  }

  return (
    <ReactMapGl
      {...viewport}
      {...mapSize}
      className="map"
      mapboxApiAccessToken="pk.eyJ1IjoiLW8tbGVza2l2LSIsImEiOiJjanZ0YTJlYzIxNjllM3lscTRvZjRtdWU3In0.o53i5LL9GfLnHs5-6EHLIw"
      mapStyle="mapbox://styles/-o-leskiv-/cjvtcksgl0sv01cpm64mlejzx"
      onViewportChange={viewport => {
        setViewport(viewport)
      }}
    >
      <Marker {...markerPosition}>
        <div
          className="map__marker"
          onClick={() => {
            togglePopup(!showPopup)
          }}
        >
          <img src={MarkerIcon} />
        </div>
      </Marker>
      {showPopup && (
        <Popup className="map__popup" {...popupPosition}>
          <span>15 Leake Street Fremantle Western Australia 6160</span>
        </Popup>
      )}
    </ReactMapGl>
  )
}

export default Map
