import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import HomeHeading from '../HomeHeading/HomeHeading'
import ContactLink from './ContactLink/ContactLink'
import Map from './Map/Map'
import Form from './ContactForm/ContactForm'

import { ContactLinkProps } from './ContactLink'

import './Contacts.scss'

const links: ContactLinkProps[] = [
  {
    title: 'Address',
    href: 'https://goo.gl/maps/2kLQuxyMThU2eEX6A',
    text: '15 Leake Street Fremantle Western Australia 6160',
    target: '_blank',
    address: true,
  },
  {
    title: 'Email',
    href: `mailto:${process.env.GATSBY_EMAIL_TARGET}`,
    text: `${process.env.GATSBY_EMAIL_TARGET}`,
    target: '',
  },
  {
    title: 'Phone',
    href: 'tel:61893360000',
    text: '+61 8 9336 0000',
  },
]

const Contacts: React.FunctionComponent = (): JSX.Element => {
  const data = useStaticQuery(query)

  return (
    <section id="contacts" className="contacts">
      <Container>
        <HomeHeading title="Contact us" />

        {/* <Row>
          <Col xs={{ span: '10', offset: '1' }}>
            <Row>
              {links.map((link, i) => {
                return (
                  <Col key={i}>
                    <div className="contacts__wrapper">
                      <h4 className="contacts__title">{link.title}</h4>
                      {link.address ? (
                        <address>
                          <ContactLink {...link} />
                        </address>
                      ) : (
                        <ContactLink {...link} />
                      )}
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row> */}
      </Container>
      <section className="contacts__map-form">
        <Img fluid={data.bgImage.childImageSharp.fluid} />
        <Container>
          <Row>
            <Col>
              <div className="map-form__wrapper">
                <Map />
                <Form />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  )
}

export default Contacts

export const query = graphql`
  query {
    bgImage: file(relativePath: { eq: "bg-contacts@3x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
