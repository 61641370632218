import * as React from 'react'
import * as classNames from 'classnames'
import Tooltip from '../Tooltip/Tooltip'

import { FormFieldProps, getComponentPropsByType, FormFieldType } from '.'

import warnIcon from '../../images/warning.svg'

import './FormField.scss'

const FormField: React.FunctionComponent<FormFieldProps> = (
  props
): JSX.Element => {
  const Comp = props.type === FormFieldType.textarea ? TextreaComp : InputComp

  return (
    <div
      className={classNames('form-field', {
        error: props.error && props.touched,
      })}
    >
      <Comp {...props} />

      <Tooltip title={props.error} id={props.name} placement="bottom-end">
        <div className="form-field__warning">
          <img src={warnIcon} />
        </div>
      </Tooltip>
    </div>
  )
}

const InputComp = (props: FormFieldProps) => (
  <input className="form-field__input" {...getComponentPropsByType(props)} />
)

const TextreaComp = (props: FormFieldProps) => (
  <textarea
    className="form-field__textarea"
    {...getComponentPropsByType(props)}
  />
)

FormField.defaultProps = {
  type: FormFieldType.text,
}

export default FormField
