import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'

import HomeHeading from '../HomeHeading/HomeHeading'
import ServicesCard from './ServicesCard/ServicesCard'

import { getImage } from '../../utils/image'

import { ServiceCardProps } from './ServicesCard'

import './Services.scss'

const Services: React.FunctionComponent = (): JSX.Element => {
  const {
    allStrapiSectionservices: {
      nodes: [data],
    },
  } = useStaticQuery(pageQuery)

  const { title, description, servicesitems } = data

  const formattedData: ServiceCardProps[] = servicesitems.map((card: any) => {
    return {
      title: card.title,
      text: card.description,
      image: getImage(card.image),
    }
  })

  return (
    <section id="services" className="services">
      <Container>
        <HomeHeading title={title} subtitle={description} />

        <Row>
          <Col className="services__col" xs={{ span: '10', offset: '1' }}>
            <Row>
              {formattedData.map((card, i: number) => (
                <Col className="col-class" key={i} xs={6}>
                  <ServicesCard {...card} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    allStrapiSectionservices {
      nodes {
        title
        description
        servicesitems {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 452, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          description
        }
      }
    }
  }
`
