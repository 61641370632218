import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'

import HomeHeading from '../HomeHeading/HomeHeading'
import ProductsCard from './ProductsCard/ProductsCard'

import { getImage } from '../../utils/image'

import { ProductsCardProps } from './ProductsCard'

import './Products.scss'

const Products: React.FunctionComponent = (): JSX.Element => {
  const {
    allStrapiSectionproducts: {
      nodes: [data],
    },
  } = useStaticQuery(pageQuery)

  const { title, description, meatgroups } = data

  const formattedData: ProductsCardProps[] = meatgroups.map((card: any) => ({
    image: getImage(card.image),
    title: card.name,
    url: card.link,
  }))

  return (
    <section id="products" className="products">
      <Container>
        <HomeHeading title={title} subtitle={description} />
        <Row>
          <Col xs={{ span: '10', offset: '1' }}>
            <Row>
              {formattedData.map((card, i: number) => (
                <Col key={i} xs={4}>
                  <ProductsCard {...card} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Products

export const pageQuery = graphql`
  query {
    allStrapiSectionproducts {
      nodes {
        title
        description
        meatgroups {
          name
          description
          link
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 340, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
