import { SuperAgentRequest } from 'superagent'

export enum ApiMethod {
  UNKNOWN = 1,
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
}

export enum ApiOperation {
  Login,
  GetOffers,
  PostOffer,
  GetTokenBySecret,
}

export interface INetwork<C> {
  getHttpInstance: (config: C) => SuperAgentRequest
  request: <T, U>(
    operation: T,
    params?: Object,
    data?: U,
    urlParams?: Object
  ) => Promise<any>
}

export interface IApi<U> {
  readonly operation: U
  readonly params: Object | undefined
  readonly data: any | undefined

  getMethod: () => ApiMethod
  getUrl: () => string
  getParams: () => Object | undefined
  getData: () => any | undefined
  isProtected: () => boolean
}

export interface IError {
  status: number
  code: number[]
  title: string
  details: any
  chainedErrors: any
}

export interface IAlegroApi {
  operation: ApiOperation
  params?: Object | undefined
  data?: any
  headers?: Object
  queryType?: string
}

export class AlegroApi implements IApi<ApiOperation> {
  readonly operation: ApiOperation
  readonly params: Object | undefined
  readonly data: any | undefined
  readonly queryType: string | undefined
  readonly headers: Object | undefined

  constructor(options: IAlegroApi) {
    const { operation, params, data, queryType, headers } = options

    this.operation = operation
    this.params = params
    this.data = data
    this.queryType = queryType
    this.headers = headers
  }

  getMethod(): ApiMethod {
    switch (this.operation) {
      case ApiOperation.Login:
      case ApiOperation.PostOffer:
      case ApiOperation.GetTokenBySecret:
        return ApiMethod.POST
      case ApiOperation.GetOffers:
        return ApiMethod.GET
      default:
        return ApiMethod.UNKNOWN
    }
  }

  getUrl(): string {
    const host = process.env.GATSBY_API_URL

    switch (this.operation) {
      case ApiOperation.Login:
        return `${host}/auth/local`
      case ApiOperation.PostOffer:
        return `${host}/offers/submit`
      case ApiOperation.GetOffers:
        return `${host}/offers/detail`
      case ApiOperation.GetTokenBySecret:
        return `${host}/offers/secret`

      default:
        return ''
    }
  }

  getParams(): Object | undefined {
    return this.params
  }

  getData(): any | undefined {
    return this.data
  }

  isProtected(): boolean {
    switch (this.operation) {
      case ApiOperation.Login:
      case ApiOperation.GetTokenBySecret:
        return false
      case ApiOperation.GetOffers:
      case ApiOperation.PostOffer:
        return true
      default:
        return false
    }
  }

  getHeaders(): Object {
    return this.headers || {}
  }
}
