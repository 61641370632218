import * as React from 'react'

import { ModalCloseButtonProps } from '.'

import CloseIcon from '../../../images/close-icon.svg'

import './ModalCloseButton.scss'

const ModalCloseButton: React.FunctionComponent<ModalCloseButtonProps> = ({
  onClose,
}): JSX.Element => (
  <button className="close-btn" onClick={() => onClose()}>
    <img src={CloseIcon} alt="Close" />
  </button>
)

export default ModalCloseButton
