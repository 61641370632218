import * as React from 'react'

import Layout from '../components/Layout/Layout'
import Slider from '../components/Slider/Slider'
import Services from '../components/Services/Services'
import Products from '../components/Products/Products'
import Brands from '../components/Brands/Brands'
import Team from '../components/Team/Team'
import Certificate from '../components/Certificate/Certificate'
import Clients from '../components/Clients/Clients'
import Contacts from '../components/Contacts/Contacts'

import { HeaderStyle, HeaderPosition } from '../components/Header'

import '../styles/styles.scss'

const IndexPage = () => (
  <Layout
    seo={{ title: 'Home' }}
    header={{
      style: HeaderStyle.light,
      position: HeaderPosition.fixed,
    }}
  >
    <Slider />
    <Services />
    <Products />
    <Brands />
    <Team />
    <Clients />
    <Certificate />
    <Contacts />
  </Layout>
)

export default IndexPage
