import * as React from 'react'

import SEO from '../Seo/Seo'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

import { LayoutProps } from '.'

import './Layout.scss'

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  seo,
  header,
  footer,
}): JSX.Element => {
  return (
    <React.Fragment>
      <SEO {...seo} />
      <Header {...header} />
      <main className="root-page__content">{children}</main>
      <Footer {...footer} />
    </React.Fragment>
  )
}

export default Layout
