import * as React from 'react'
import FormField from '../../FormField/FormField'
import { Formik } from 'formik'

import validateForm from '../../../utils/validate'
import { topFields, bottomFields, middleFields } from './fields'

import './ContactForm.scss'

const Form: React.FunctionComponent = (): JSX.Element => {
  const formEl: React.MutableRefObject<any> = React.useRef(null)

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        message: '',
        _next: `//${process.env.GATSBY_DOMAIN}`,
      }}
      onSubmit={() => {
        formEl.current.submit()
      }}
      validate={validateForm([...topFields, ...middleFields, ...bottomFields])}
    >
      {({ values, handleChange, handleSubmit, errors, touched }: any) => {
        return (
          <form
            ref={formEl}
            action={`https://formspree.io/${process.env.GATSBY_EMAIL_TARGET}`}
            method="POST"
            noValidate
            className="contact-form"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="_next" value={values._next} />
            <input type="hidden" name="_subject" value="Contact Us Form" />
            <input type="text" name="_gotcha" style={{ display: 'none' }} />

            <h4 className="contact-form__title">Please lodge your inquiry</h4>
            <div className="top-fields__wrapper">
              {topFields.map((field, i) => (
                <FormField
                  key={i}
                  {...field}
                  value={values[field.name]}
                  onChange={handleChange}
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              ))}
            </div>
            <div className="middle-fields__wrapper">
              {middleFields.map((field, i) => (
                <FormField
                  key={i}
                  {...field}
                  value={values[field.name]}
                  onChange={handleChange}
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              ))}
            </div>
            <div className="bottom-fields__wrapper">
              {bottomFields.map((field, i) => (
                <FormField
                  key={i}
                  {...field}
                  value={values[field.name]}
                  onChange={handleChange}
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              ))}
            </div>
            <button className="primary-button" type="submit">
              Send
            </button>
          </form>
        )
      }}
    </Formik>
  )
}

export default Form
