import * as React from 'react'
import Img from 'gatsby-image'

import { ServiceCardProps } from '.'

import './ServicesCard.scss'

const ServicesCard: React.FunctionComponent<ServiceCardProps> = ({
  title,
  text,
  image,
}): JSX.Element => (
  <div className="service-card d-flex">
    <div className="service-card__image-wrapper">
      <Img fluid={image} />
    </div>
    <div className="service-card__content">
      <div className="service-card__header">
        <h4 className="service-card__title">{title}</h4>
      </div>
      <p className="service-card__text">{text}</p>
    </div>
  </div>
)

ServicesCard.defaultProps = {
  title: '',
  text: '',
  image: undefined,
}

export default ServicesCard
