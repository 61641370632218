import * as React from 'react'
import TextTruncate from 'react-text-truncate'

import { ClientsCardProps } from '.'

import './ClientsCard.scss'

const ClientsCard: React.FunctionComponent<ClientsCardProps> = ({
  id,
  name,
  company,
  info,
  handler = (id: string) => {},
}): JSX.Element => {
  return (
    <article className="client-card">
      <div className="client-card__header">
        <h4 className="client-card__name">{name}</h4>
        <span className="client-card__position">{company}</span>
      </div>
      <div className="client-card__info">
        <TextTruncate
          line={5}
          textElement="p"
          truncateText="... ”"
          text={info}
          textTruncateChild={
            <button className="more-btn" onClick={() => handler(id)}>
              More
            </button>
          }
        />
      </div>
    </article>
  )
}

ClientsCard.defaultProps = {
  handler: () => {},
}

export default ClientsCard
