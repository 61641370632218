import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { Carousel, CarouselItem } from 'react-bootstrap'

import { getImageByPath } from '../../utils/image'

import './Slider.scss'

const images: string[] = [
  'slider/slides/slide1@2x.jpg',
  'slider/slides/slide2@2x.jpg',
  'slider/slides/slide3@2x.jpg',
]

const Slider: React.FunctionComponent<any> = (): JSX.Element => {
  const {
    allFile: { edges: data },
  } = useStaticQuery(query)

  return (
    <section className="slider">
      <div className="main-heading d-flex">
        <h1>
          Exporting Australian <br /> Meat Products to The World
        </h1>
        <span className="main-heading__subtitle">Since 1966</span>
      </div>
      <div className="slider__wrapper">
        <Carousel controls={false} pauseOnHover={false}>
          {images.map((image, i) => {
            const imageObj = getImageByPath<FluidObject>(data, image)
            if (!imageObj) return null

            return (
              <CarouselItem key={i}>
                <Img fluid={imageObj} />
              </CarouselItem>
            )
          })}
        </Carousel>
      </div>
    </section>
  )
}

export default Slider

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { regex: "/slider/slides/" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1440, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
